import React, { useContext } from 'react';
import _ from 'lodash';

import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, adminPagesMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';
import BusinessContext from '../../../contexts/businessContext';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { isAdmin } = useContext(AuthContext);
	const { business } = useContext(BusinessContext);

	const getAdminPagesMenu = () => {
		if (isAdmin) {
			return (
				<>
					<NavigationLine />
					<Navigation menu={adminPagesMenu} id='aside-admin-pages' />
				</>
			);
		}
		return null;
	};

	const getBusinessPagesMenu = () => {
		if (!_.isEmpty(business)) {
			return <Navigation menu={dashboardPagesMenu} id='aside-dashboard-pages' />;
		}
		return null;
	};

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{getBusinessPagesMenu()}
				{getAdminPagesMenu()}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;

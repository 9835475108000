import React, { lazy } from 'react';
import {
	dashboardPagesMenu,
	authPagesMenu,
	pageLayoutTypesPagesMenu,
	adminPagesMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};

const PAGE_LAYOUTS = {
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const PAGES = {
	BUSINESS_PROFILE: lazy(
		() => import('../pages/presentation/business-profile/BusinessProfilePage'),
	),
	CAMPAIGN_PAGE: lazy(() => import('../pages/presentation/campaigns/CampaignListPage')),
	NOTIFICATION_MANAGEMENT: lazy(
		() => import('../pages/presentation/customers/CustomerManagement'),
	),
	FLYER_GENERATOR: lazy(() => import('../pages/presentation/flyer/FlyerPage')),
};

const ADMIN_PAGES = {
	DASHBOARD: lazy(() => import('../pages/presentation/admin/dashboard/AdminDashboardPage')),
	BUSINESS_DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	BUSINESS_MANAGEMENT: lazy(
		() => import('../pages/presentation/admin/business/BusinessManagement'),
	),
	STAFF_MANAGEMENT: lazy(() => import('../pages/presentation/admin/staff/StaffManagement')),
	CAMPAIGN_MANAGEMENT: lazy(
		() => import('../pages/presentation/admin/campaign/CampaignManagement'),
	),
	NOTIFICATION_MANAGEMENT: lazy(
		() => import('../pages/presentation/customers/CustomerManagement'),
	),
	FLYER_GENERATOR: lazy(() => import('../pages/presentation/flyer/FlyerPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardPagesMenu.businessProfile.path,
		element: <PAGES.BUSINESS_PROFILE />,
	},
	{
		path: dashboardPagesMenu.campaigns.path,
		element: <PAGES.CAMPAIGN_PAGE />,
	},
	{
		path: dashboardPagesMenu.notifications.path,
		element: <PAGES.NOTIFICATION_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.flyerGenerator.path,
		element: <PAGES.FLYER_GENERATOR />,
	},
	{
		path: authPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: authPagesMenu.signUp.path,
		element: <Login />,
	},
	{
		path: adminPagesMenu.adminDashboard.path,
		element: <ADMIN_PAGES.DASHBOARD />,
	},
	{
		path: adminPagesMenu.businessDashboard.path,
		element: <ADMIN_PAGES.BUSINESS_DASHBOARD />,
	},
	{
		path: adminPagesMenu.businessManagement.path,
		element: <ADMIN_PAGES.BUSINESS_MANAGEMENT />,
	},
	{
		path: adminPagesMenu.staffManagement.path,
		element: <ADMIN_PAGES.STAFF_MANAGEMENT />,
	},
	{
		path: adminPagesMenu.campaignManagement.path,
		element: <ADMIN_PAGES.CAMPAIGN_MANAGEMENT />,
	},
	{
		path: adminPagesMenu.notificationManagement.path,
		element: <ADMIN_PAGES.NOTIFICATION_MANAGEMENT />,
	},
	{
		path: adminPagesMenu.flyerGenerator.path,
		element: <ADMIN_PAGES.FLYER_GENERATOR />,
	},
	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [...presentation];

export default contents;

import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { UserData } from '../common/data/user-data';

export interface IAuthContextProps {
	user: UserData;
	setUser({}: UserData): void;
	setUid: (uid: string) => void;
	isAdmin: boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const userStoredData = localStorage.getItem('mudavim_authUser');
	const [user, setUser] = useState<UserData>(userStoredData ? JSON.parse(userStoredData) : {});

	useEffect(() => {
		localStorage.setItem('mudavim_authUser', JSON.stringify(user));
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			setUid: (uid: string) => {
				setUser({ ...user, uid });
			},
			isAdmin: !_.isEmpty(user?.adminData?.uid),
		}),
		[user],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
